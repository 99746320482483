(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

$(function () {

    var $pageNavigationToggle = $('#Page_Navigation_Toggle');
    var $pageNavigation = $('.Page_Navigation');
    var $pageNavigationElements = $('#Page_Navigation_Toggle, .Page_Navigation');
    var $pageNavigationMenuElement = $('.Page_Navigation-Menu li');

    $pageNavigationToggle.click(function () {
        $pageNavigation.toggleClass('Page_Navigation--opened-mobile');
        $('body').toggleClass('Page_Navigation-visible');
    });

    $(document).on('click touchstart', function (event) {
        if (!$(event.target).closest($pageNavigationElements).length) {
            $pageNavigation.removeClass('Page_Navigation--opened-mobile');
            $('body').removeClass('Page_Navigation-visible');
        }
    });

    $pageNavigationMenuElement.on('click', function () {
        if ($(this).hasClass('Page_Navigation-Submenu-opened-mobile')) {
            $(this).toggleClass('Page_Navigation-Submenu-opened-mobile');
        } else {
            $pageNavigationMenuElement.removeClass('Page_Navigation-Submenu-opened-mobile');
            $(this).toggleClass('Page_Navigation-Submenu-opened-mobile');
        }
    });
});

},{}]},{},[1]);
